import { onLogin, apolloClient } from '@/plugins/vue-apollo.js'
import { saveToLocalStorage } from '../../base-module/util/localStorageHandler'
import { doMutation } from '@/app/base-module/util/doMutation'

const adminSignIn = (phoneOrEmail, password, pushToken) => {
  const mutaion = require('@/app/user-module/api/mutation/signin_mutaion.gql')
  const variables = {
    phoneOrEmail,
    password,
    firebaseToken: pushToken
  }
  console.log(variables)
  let auth = doMutation(mutaion, variables)
    .then(data => {
      // console.log(data)
      saveUserAndToken(apolloClient, data.data)
      return {
        data: data.data
      }
    })
    .catch(error => {
      console.error(error)
      return {
        error: JSON.stringify(error.message)
      }
    })
  return auth
}

function saveUserAndToken(apolloClient, data) {
  onLogin(apolloClient, data.userSignIn.token)

  saveToLocalStorage('User', JSON.stringify(data.userSignIn.user))
  saveToLocalStorage('Role', data.userSignIn.user.role)

  location.reload()
}

export { adminSignIn }
