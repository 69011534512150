<template>
  <v-container>
    <v-layout row justify-center class="pa-4">
      <v-flex xs12 md6 class="mt-12">
        <v-card class="ma-2 px-8 py-12 mt-sm-12" elevation="0" rounded="lg">
          <h2 class="mb-6 primary--text">{{ $t('user.signIn.title') }}</h2>
          <!-- signin form  -->
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                  color="primary"
                  class="mb-n1 caption primary--text"
                  v-model="phoneOrEmail"
                  :label="$t('user.signIn.phoneOrEmail')"
                  @click="errorFromApi = ''"
                  dense
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider rules="required">
                <v-text-field
                  dense
                  color="primary"
                  class="mb-n1 caption primary--text"
                  v-model="password"
                  :label="$t('user.signIn.password')"
                  @click="errorFromApi = ''"
                  :append-icon="password_value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (password_value = !password_value)"
                  :type="password_value ? 'password' : 'text'"
                  :password_value="password"
                ></v-text-field>
              </ValidationProvider>
              <p v-if="errorFromApi" class="error--text">{{ errorFromApi }}</p>

              <v-hover v-slot="{ hover }">
                <v-btn
                  class="mt-2 rounded primary"
                  depressed
                  small
                  :loading="btn_loading"
                  @click="signin()"
                  :disabled="invalid"
                >
                  {{ $t('user.signIn.title') }}
                  <v-icon size="20" v-show="hover" right
                    >mdi-chevron-right</v-icon
                  >
                </v-btn>
              </v-hover>
            </form>
          </ValidationObserver>
          <!-- end of signin form  -->
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { adminSignIn } from '../usecase/adminSignIn.js'
import { onLogin } from '@/plugins/vue-apollo.js'
import '@/app/base-module/util/validation_rules'
export default {
  data: () => {
    return {
      btn_loading: false,
      phoneOrEmail: '',
      password: '',
      password_value: String,
      errorFromApi: null
    }
  },
  methods: {
    signin() {
      this.btn_loading = true
      let pushToken = localStorage.getItem(
        `${process.env.VUE_APP_NAME}-4-push-token`
      )
      adminSignIn(this.phoneOrEmail, this.password, pushToken).then(
        response => {
          if (!!response.error) {
            if (response.error.includes('Not Found')) {
              this.errorFromApi = this.$t('user.signIn.invalidLogin')
              this.btn_loading = false
            } else {
              this.errorFromApi = this.$t('general.somethingWentWrong')
              this.btn_loading = false
              console.log('response.error')
            }
          } else if (!!response.data) {
            // console.log(response.data)
            this.btn_loading = false
            this.$router.replace({ name: 'Customers' })
          } else {
          }
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
